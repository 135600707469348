import axios from "axios";
import {jwtDecode} from "jwt-decode";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const OTP_SENT = "OTP_SENT";
export const COMPLETE_LOGIN = "COMPLETE_LOGIN";
export const COMPLETE_LOGIN_FAIL = "COMPLETE_LOGIN_FAIL";

const API_URL = `${process.env.REACT_APP_BASE_URL}/auth`


export const loginUser = (mobileNumber) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const { data } = await axios.post(`${API_URL}/login`, {
      mobileNumber,
    });

    dispatch({ type: OTP_SENT });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response?.data?.message || "Failed to send OTP",
    });
  }
};

// Action for completing login with OTP
export const completeLogin = (mobileNumber, otp) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${API_URL}/complete-login`,
      { mobileNumber, otp }
    );

    const { token } = data.access;
    const decodedToken = jwtDecode(token);
    const role = decodedToken.role
    
    dispatch({ type: COMPLETE_LOGIN, payload: token });
      window.location.href = "/admin/house"; 
   
  } catch (error) {
    dispatch({
      type: COMPLETE_LOGIN_FAIL,
      payload: error.response?.data?.message || "Invalid OTP",
    });
  }
};
