// authReducer.js
import { LOGIN_REQUEST, LOGIN_FAIL, OTP_SENT, COMPLETE_LOGIN, COMPLETE_LOGIN_FAIL } from "../actions/authActions";

const initialState = {
  loading: false,
  error: null,
  isOtpSent: false,
  userRole: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case OTP_SENT:
      return { ...state, loading: false, isOtpSent: true };
    case COMPLETE_LOGIN:
        localStorage.setItem('token',action.payload)
      return { ...state, loading: false, userRole: "ADMIN" };
    case COMPLETE_LOGIN_FAIL:
    case LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
