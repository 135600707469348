import {
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    UPDATE_USER_FIELD_REQUEST,
    UPDATE_USER_FIELD_SUCCESS,
    UPDATE_USER_FIELD_FAILURE,
  } from '../actions/userActions';
  
  const initialState = {
    loading: false,
    users: [],
    error: null,
  };
  
  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_REQUEST:
      case CREATE_USER_REQUEST:
      case UPDATE_USER_FIELD_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          users: action.payload,
        };
  
      case CREATE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          users: [...state.users, action.payload],
        };
  
      case UPDATE_USER_FIELD_SUCCESS:
        return {
          ...state,
          loading: false,
          users: state.users.map((user) =>
            user.id === action.payload.userId
              ? {...user, user: action.payload }
              : user
          ),
        };
  
      case FETCH_USER_FAILURE:
      case CREATE_USER_FAILURE:
      case UPDATE_USER_FIELD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  