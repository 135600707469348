// users/components/UserTable.jsx
import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Card } from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

const UserTable = ({ users, onDelete, onEdit }) => {
    // Helper function to format the date
const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options); // Formats date as "dd-Month-Year"
  };
  return (
<Card>
    <Table variant="simple" colorScheme="blue">
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Role</Th>
          <Th>createdAt</Th>
          <Th>updatedAt</Th>
          <Th>mobileNumber</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {users.map((user) => (
          <Tr key={user.id}>
            <Td>{user.id}</Td>
            <Td>{user.name}</Td>
            <Td>{user.email?user.email:"Null"}</Td>
            <Td>{user.role}</Td>
            <Td>{formatDate(user.createdAt)}</Td>
            <Td>{formatDate(user.updatedAt)}</Td>
            <Td>{user.mobileNumber}</Td>


            <Td>
              <IconButton
                icon={<EditIcon />}
                onClick={() => onEdit(user)}
                mr={2}
                aria-label="Edit User"
              />
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => onDelete(user.id)}
                colorScheme="blue"
                aria-label="Delete User"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
 </Card>
  );
};

export default UserTable;
