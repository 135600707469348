import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Card, Input, Flex } from '@chakra-ui/react';

const UserTable = ({ users, onShowTransactions }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter users based on search term
  const filteredUsers = users.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.id.toString().includes(searchLower) ||
      user.full_name.toLowerCase().includes(searchLower) ||
      (user.email && user.email.toLowerCase().includes(searchLower)) ||
      new Date(user.created_at).toLocaleDateString().toLowerCase().includes(searchLower)
    );
  });

  return (
    <>
    <Flex mb={4} justifyContent="flex-start">
    <Input
      placeholder="Search by ID, Name, Email, Created At"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      width="300px"
      mr={4}
      backgroundColor="white"
    />
  </Flex>
    <Card>
     
      <Table variant="simple" colorScheme="blue">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Full Name</Th>
            <Th>Email</Th>
            <Th>Created At</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredUsers.map((user) => (
            <Tr key={user.id}>
              <Td>{user.id}</Td>
              <Td>{user.full_name}</Td>
              <Td>{user.email ? user.email : 'N/A'}</Td>
              <Td>{new Date(user.created_at).toLocaleDateString()}</Td>
              <Td>
                <Button colorScheme="blue" onClick={() => onShowTransactions(user.id)}>
                  Show Transactions
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Card>
    </>
  );
};

export default UserTable;
