
import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Card, Box, Select, Flex, Icon } from '@chakra-ui/react';
import { MdArrowRight } from 'react-icons/md';

const TransactionTable = ({ transactions, filterChange, filter }) => {
  const handleBack = () => {
    window.location.reload();
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" cursor="pointer" onClick={handleBack} mb={4} justifyContent="flex-start">
          <p style={{ color: 'blue', marginRight: '4px' }}>Back</p>
          <Icon as={MdArrowRight} width="20px" height="20px" color="blue" />
        </Flex>

        {/* Filter Section */}
        <Flex mb={4} justifyContent="flex-end" wrap="wrap" gap={4}>
          <Box>
            <Select
              name="transactionType"
              placeholder="Transaction Type"
              width="200px"
              backgroundColor="white"
              value={filter.transactionType}
              onChange={filterChange}
            >
              <option value="payment">Payment</option>
              <option value="refund">Refund</option>
              <option value="disbursement">Disbursement</option>
              <option value="deposit">Deposit</option>
              <option value="withdrawal">Withdrawal</option>
            </Select>
          </Box>
          <Box>
            <Select
              name="transactionTypeMethod"
              placeholder="Transaction Method"
              width="200px"
              backgroundColor="white"
              value={filter.transactionTypeMethod}
              onChange={filterChange}
            >
              <option value="credit_card">Credit Card</option>
              <option value="npp">NPP</option>
              <option value="bpay">BPAY</option>
              <option value="wallet_account_transfer">Wallet Account Transfer</option>
              <option value="wire_transfer">Wire Transfer</option>
            </Select>
          </Box>
          <Box>
            <Select
              name="direction"
              placeholder="Direction"
              width="200px"
              backgroundColor="white"
              value={filter.direction}
              onChange={filterChange}
            >
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </Select>
          </Box>
        </Flex>
      </Flex>

      <Card mt={4}>
        <Table variant="simple" colorScheme="blue">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Description</Th>
              <Th>Type</Th>
              <Th>Method</Th>
              <Th>State</Th>
              <Th>Amount</Th>
              <Th>Currency</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((transaction) => (
              <Tr key={transaction.id}>
                <Td>{transaction.id}</Td>
                <Td>{transaction.description}</Td>
                <Td>{transaction.type}</Td>
                <Td>{transaction.type_method}</Td>
                <Td>{transaction.state}</Td>
                <Td>{transaction.amount / 100}</Td>
                <Td>{transaction.currency}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </>
  );
};

export default TransactionTable;
