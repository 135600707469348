// users/components/UserFormModal.jsx
import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const UserFormModal = ({ isOpen, onClose, onSubmit,onEdit, initialUser }) => {
  const [user, setUser] = useState({ name: '', email: '', mobileNumber: '', password: '', role: '' });

  useEffect(() => {
    if (initialUser) {
      setUser(initialUser);
    } else {
      setUser({ name: '', email: '', mobileNumber: '', password: '', role: ''});
    }
  }, [initialUser]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    onSubmit(user);
    onClose();
  };

  const handleUpdate = () => {
    onEdit(user);
    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{initialUser ? 'Edit User' : 'Create User'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={user.name}
              onChange={handleChange}
              placeholder="Enter user name"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={user.email}
              onChange={handleChange}
              placeholder="Enter user email"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Mobile Number</FormLabel>
            <Input
              name="mobileNumber"
              value={user.mobileNumber}
              onChange={handleChange}
              placeholder="Enter user mobileNumber"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              name="password"
              value={user.password}
              onChange={handleChange}
              placeholder="Enter user password"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Role</FormLabel>
            <Input
              name="role"
              value={user.role}
              onChange={handleChange}
              placeholder="Enter user role"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={initialUser?handleUpdate:handleSubmit}>
            {initialUser ? 'Update' : 'Create'}
          </Button>
          <Button ml={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserFormModal;
