import axios from 'axios';
import swal from 'sweetalert';

// Action Types
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_FIELD_REQUEST = 'UPDATE_USER_FIELD_REQUEST';
export const UPDATE_USER_FIELD_SUCCESS = 'UPDATE_USER_FIELD_SUCCESS';
export const UPDATE_USER_FIELD_FAILURE = 'UPDATE_USER_FIELD_FAILURE';

const API_URL = `${process.env.REACT_APP_BASE_URL}/users`; 
const token = localStorage.getItem('token')
// Fetch User Action
export const fetchUsers = () => async (dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/getAll`,{
      headers:{
        Authorization: `Bearer ${token}`,
      }
    });
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_USER_FAILURE,
      payload: error.message,
    });
    swal('Error', 'Failed to fetch users.', 'error');
  }
};

// Create User Action
export const createUser = (userData) => async (dispatch) => {
  dispatch({ type: CREATE_USER_REQUEST });
  try {
    const response = await axios.post(`${API_URL}/create`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: response.data,
    });
    swal('Success', 'User created successfully!', 'success');
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAILURE,
      payload: error.message,
    });
    swal('Error', 'Failed to create house.', 'error');
  }
};

// Update House Field Action
export const updateUserField = (userData) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_FIELD_REQUEST });
  try {
    // Update user data
    const updatedUserData = {
      userId: userData.id, 
      name:userData.name, 
      email:userData.email, 
      mobileNumber:userData.mobileNumber,
      password:userData.password, 
      role:userData.role 
    };

  const response=  await axios.put(`${API_URL}/update`, updatedUserData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: UPDATE_USER_FIELD_SUCCESS,
      payload: updatedUserData,
    });
    swal('Success', 'User updated successfully!', 'success');
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FIELD_FAILURE,
      payload: error.message,
    });
    swal('Error', 'Failed to update user.', 'error');
  }
};
