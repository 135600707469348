// reducers/userReducer.js
import {
    FETCH_USERS_SUCCESS,
    FETCH_USERS_ERROR,
    FETCH_TRANSACTIONS_SUCCESS,
    FETCH_TRANSACTIONS_ERROR,
  } from '../actions/transactionActions';
  
  const initialState = {
    users: [],
    transactions: [],
    loading: false,
    error: null,
  };
  
  export const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USERS_SUCCESS:
        return { ...state, users: action.payload, loading: false };
      case FETCH_USERS_ERROR:
        return { ...state, error: action.payload, loading: false };
      case FETCH_TRANSACTIONS_SUCCESS:
        return { ...state, transactions: action.payload, loading: false };
      case FETCH_TRANSACTIONS_ERROR:
        return { ...state, error: action.payload, loading: false };
      default:
        return state;
    }
  };
  