import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import {thunk} from 'redux-thunk'; // Correct import for thunk middleware
import { houseReducer } from './reducers/houseReducers';
import { userReducer } from 'reducers/userReducers';
import {transactionReducer} from 'reducers/transactionReducers'
import {authReducer} from 'reducers/authReducers'
const rootReducer = combineReducers({
  house: houseReducer,
  users: userReducer,
  transactions: transactionReducer,
  auth: authReducer,
 
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const StoreProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
