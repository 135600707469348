// actions/userActions.js
import axios from 'axios';

// Action Types
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';
const API_URL = process.env.REACT_APP_BASE_URL
// Fetch Users Action

export const fetchUsers = () => async (dispatch) => {
  try {
    const {data} = await axios.get(`${API_URL}/auth/getZaiToken`);
    const token = data.token;
    const response = await axios.get(`https://test.api.promisepay.com/users`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data.users });
  } catch (error) {
    dispatch({ type: FETCH_USERS_ERROR, payload: error.message });
  }
};

// Fetch Transactions Action
export const fetchTransactions = (userId, transactionType="", transactionTypeMethod="", direction="") => async (dispatch) => {
  try {
    const {data} = await axios.get(`${API_URL}/auth/getZaiToken`);
    const token = data.token;
    const response = await axios.get(`https://test.api.promisepay.com/transactions?user_id=${userId}&transaction_type=${transactionType}&transaction_type_method=${transactionTypeMethod}&direction=${direction}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
 
    dispatch({ type: FETCH_TRANSACTIONS_SUCCESS, payload: response.data.transactions });
  } catch (error) {
    dispatch({ type: FETCH_TRANSACTIONS_ERROR, payload: error.message });
  }
};
