
import React, { useState, useEffect } from 'react';
import { Button, Box, useDisclosure, Input, Flex, HStack } from '@chakra-ui/react';
import UserTable from './components/UserTable';
import UserFormModal from './components/UserFormModal';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers, createUser, updateUserField } from '../../../actions/userActions';

const Index = () => {
  const [editingUser, setEditingUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const usersSelector = useSelector(state => state.users);
  const { loading, error, users } = usersSelector;
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const usersPerPage = 10; // Number of users per page

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    // Filter users based on the search term
    const filtered = users.filter((user) => {
      return (
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.mobileNumber && user.mobileNumber.toString().includes(searchTerm)) ||
        (user.role && user.role.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  // Calculate the users to be displayed based on the current page
  const paginatedUsers = filteredUsers.slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);

  const handleCreate = async (user) => {
    dispatch(createUser(user));
  };

  const handleUpdate = async (user) => {
    dispatch(updateUserField(user));
  };

  const handleDeleteUser = async (userId) => {
    // Delete user API call
    // dispatch(deleteUser(userId));
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    onOpen();
  };

  const handleOpenCreateModal = () => {
    setEditingUser(null);
    onOpen();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => {
      const maxPage = Math.ceil(filteredUsers.length / usersPerPage);
      return Math.min(prevPage + 1, maxPage);
    });
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex justify="space-between" mb={4}>
        {/* <Button colorScheme="blue" onClick={handleOpenCreateModal}>
          Create New User
        </Button> */}
        <Input
          placeholder="Search by name, email, mobile number, or role"
          value={searchTerm}
          onChange={handleSearchChange}
          width="300px"
        />
      </Flex>
      <UserTable users={paginatedUsers} onDelete={handleDeleteUser} onEdit={handleEditUser} />
      <HStack justifyContent="center" mt={4}>
        <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>
          Previous
        </Button>
        <Button onClick={handleNextPage} isDisabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}>
          Next
        </Button>
      </HStack>
      <UserFormModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleCreate}
        onEdit={handleUpdate}
        initialUser={editingUser}
      />
    </Box>
  );
};

export default Index;
