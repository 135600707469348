
import React, { useEffect, useState } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import UserTable from './components/UserTable';
import TransactionTable from './components/TransactionTable';
import { fetchUsers, fetchTransactions } from '../../../actions/transactionActions';

const Index = () => {
  const dispatch = useDispatch();
  const { users, transactions, loading, error } = useSelector((state) => state.transactions);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isTransactions, setIsTtransactions] = useState(false);
  const [filter, setFilter] = useState({
    transactionType: "",
    transactionTypeMethod: "",
    direction: ""
  });

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleShowTransactions = (userId) => {
    setSelectedUserId(userId);
    setIsTtransactions(true);
    // Fetch transactions with the latest filter state after it is fully updated
    dispatch(fetchTransactions(userId, filter.transactionType, filter.transactionTypeMethod, filter.direction));
  };

  const filterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => {
      const updatedFilter = { ...prevFilter, [name]: value };
      // Fetch transactions with the updated filter state
      if (selectedUserId) {
        dispatch(fetchTransactions(selectedUserId, updatedFilter.transactionType, updatedFilter.transactionTypeMethod, updatedFilter.direction));
      }
      return updatedFilter;
    });
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {loading && <Spinner />}
      {error && <Text color="red.500">{error}</Text>}
      {!isTransactions && <UserTable users={users} onShowTransactions={handleShowTransactions} />}
      {isTransactions && selectedUserId && (
        <TransactionTable
          transactions={transactions}
          filterChange={filterChange}
          filter={filter}
        />
      )}
    </Box>
  );
};

export default Index;
